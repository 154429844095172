<template>
  <div class="main-container">
    <loader v-if="loading"></loader>
    <div class="content" v-if="!loading">
      <div class="form-container">
        <div class="form">
          <p class="title">Create a new password</p>
          <form id="app" @submit="changePassword">
            <div class="fields">
              <div
                class="field"
                :class="v$.newPassword.$dirty && v$.newPassword.$invalid && 'error'"
                @keyup="v$.newPassword.$touch()"
              >
                <p class="label">New password</p>
                <input
                  id="newPassword"
                  v-model="newPassword"
                  type="password"
                  name="newPassword"
                  class="input"
                  maxlength="100"
                  :disabled="disabled"
                />
              </div>
              <div
                class="field"
                :class="v$.$dirty && v$.confirmPassword.$invalid && 'error'"
              >
                <p class="label">Repeat new password</p>
                <input
                  id="confirmPassword"
                  v-model="confirmPassword"
                  type="password"
                  name="confirmPassword"
                  class="input"
                  maxlength="100"
                  :disabled="disabled"
                  @keyup="v$.confirmPassword.$touch()"
                />
              </div>

              <p class="error-msg invalid" v-if="!!message">{{ message }}</p>
              <div class="action">
                <button type="submit" :disabled="disabled || v$.$invalid">SAVE</button>
              </div>
              <div class="messages">
                <p
                  v-for="(validation, index) of validations"
                  v-bind:key="validation.message"
                >
                  <img
                    v-if="newPassword"
                    :src="
                      require(`../assets/images/${
                        v$.newPassword[index].$invalid ? 'warn.png' : 'tick.png'
                      }`)
                    "
                  />
                  {{ validation.message }}
                </p>
                <p>
                  <img
                    v-if="newPassword"
                    :src="
                      require(`../assets/images/${
                        v$.confirmPassword.sameAs.$invalid ? 'warn.png' : 'tick.png'
                      }`)
                    "
                  />
                  Passwords don't match
                </p>
              </div>
              <Banner
                v-if="v$.$invalid && (v$.newPassword.$dirty || v$.confirmPassword.$dirty)"
                message=" Ensure all requirements are checked to be able to save your new
                password."
              />
            </div>
          </form>
        </div>
      </div>
      <LoginImage></LoginImage>
    </div>
  </div>
  <Footer />
</template>

<script>
// @ is an alias to /src
import { Auth } from "aws-amplify";
import Loader from "../components/Loader";
import LoginImage from "../components/LoginImage.vue";
import { required, sameAs } from "@vuelidate/validators";
import useVuelidate from "@vuelidate/core";
import { isValidHttpUrl, matchRegex } from "../helpers/validation";
import Footer from "./Footer.vue";
import Banner from "../components/Banner";
const validations = [
  {
    message: "Password must contain a lower case letter",
    regex: /[a-z]/,
  },
  {
    message: "Password must contain an upper case letter",
    regex: /[A-Z]/,
  },
  {
    message: "Password must contain a special character",
    regex: /(?=.*\W)/,
  },
  {
    message: "Password must contain a number",
    regex: /\d+/,
  },
  {
    message: "Password must contain at least 12 characters",
    regex: /^.{12,}$/,
  },
];
const passwordValidations = {};
for (const [index, validation] of validations.entries()) {
  passwordValidations[index] = matchRegex(validation.message, validation.regex);
}
export default {
  setup() {
    return { v$: useVuelidate() };
  },
  async created() {
    this.loading = true;
    try {
      this.validateUrl();
      // Signout any existing user
      await Auth.signOut();
      const authenticatedUser = await Auth.signIn(this.username, this.password);

      //A check to check if its a first time login if not simply redirect to the redirect Uri
      if (!(await this.isFirstTimeUser())) {
        throw {
          message: "Not a first time login",
        };
      }
      /**
       * This returned user instance is required for resetting the password, Getting currentAuthenticatedUser
       * doesn't work as per the documentation
       */
      this.user = authenticatedUser;
      this.loading = false;
    } catch (e) {
      console.error("Error while auto login ", { e });
      /**
       * In case of invalid link or invalid username and password as well, Redirect to the url
       */
      this.$router.push({
        name: "Change-Password-Expired-Link",
        params: {
          username: this.username,
        },
      });
    }
  },
  data: () => {
    return {
      errors: [],
      newPassword: undefined,
      confirmPassword: undefined,
      loading: false,
      message: undefined,
      username: undefined,
      password: undefined,
      disabled: false,
      validations,
    };
  },
  validations: function () {
    return {
      newPassword: {
        required,
        ...passwordValidations,
      },
      confirmPassword: {
        required,
        sameAs: sameAs(this.newPassword),
      },
    };
  },
  components: { Loader, Footer, Banner, LoginImage },
  methods: {
    changePassword: async function (e) {
      e.preventDefault();
      if (this.v$.$error) return;
      this.loading = true;
      try {
        await Auth.completeNewPassword(this.user, this.newPassword);
        this.$router.push({
          name: "Change-Password-Confirmation",
        });
      } catch (e) {
        console.error("Error while changing password ", { e });
        this.message = e.message;
        this.loading = false;
      }
    },
    throwLinkExpirationError() {
      this.disabled = true;
      throw {
        message:
          "This link is invalid or expired, If you would like to still reset the password, Please contact Us.",
      };
    },
    validateUrl() {
      try {
        if (
          !this.$route.params.username ||
          !this.$route.query.password ||
          !isValidHttpUrl(this.$route.query.redirectUri)
        ) {
          throw "Invalid Link";
        }
        this.username = this.$route.params.username;
        this.password = this.$route.query.password;
      } catch (e) {
        console.error("Error while validating url ", { e });
        this.throwLinkExpirationError();
      }
    },
    redirect() {
      // Redirection Logic
      window.location.href = this.$route.query.redirectUri;
    },
    /**
     * If called after sign in, In case of first time it will throw an error
     *
     */
    async isFirstTimeUser() {
      try {
        await Auth.currentAuthenticatedUser();
        return false;
      } catch (e) {
        return true;
      }
    },
  },
};
</script>
<style scoped>
.messages {
  text-align: left;
  font-family: TitilliumWeb-Regular, sans-serif;
  color: #6d818b;
  padding-top: 10px;
}
.messages p {
  padding-bottom: 5px;
  font-size: 12px;
}
.tick {
  background: url("../assets/images/tick.png");
}
.error-banner {
  color: #aa0510;
  background: #fbf3f3;
  font-family: TitilliumWeb-Regular, sans-serif;
  font-weight: bold;
  padding: 10px 15px 10px 15px;
  border-radius: 6px;
  border: 1px solid #aa0510;
  margin-top: 20px;
  font-size: 14px;
}
.error-banner img {
  vertical-align: middle;
}
</style>
