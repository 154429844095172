<template>
  <div class="main-container">
    <Loader v-if="loading" />
    <div class="content">
      <div class="form-container">
        <div class="form">
          <p class="title">
            <img :src="require(`../assets/images/alert.png`)" />&nbsp;Expired recovery
            link
          </p>
          <div class="info">
            <p class="text">
              This link is expired or invalid. If the problem persists please contact
              <strong>ask@datamaran.com</strong>
            </p>
          </div>
          <div class="action">
            <button @click="requestNewLink">Request a new link</button>
          </div>
          <div class="links">
            <router-link class="reset" to="/">Log in Datamaran</router-link>
          </div>
        </div>
      </div>
      <LoginImage></LoginImage>
    </div>
  </div>
  <Footer />
</template>

<script>
import { ref } from "vue-demi";
import { useRouter } from "vue-router";
import Loader from "../components/Loader";
import LoginImage from "../components/LoginImage.vue";
import Footer from "./Footer.vue";
// @ is an alias to /src
export default {
  components: { Footer, Loader, LoginImage },
  props: {
    username: String,
  },
  setup(props) {
    const router = useRouter();
    const loading = ref(false);
    const requestNewLink = async () => {
      loading.value = true;
      try {
        await fetch(`/api/reset_password/email/${props.username}`);
        router.push({
          name: "Forgot-Password-Confirmation",
        });
      } catch (e) {
        console.error("Error while resetting password", e);
      }
      loading.value = false;
    };

    return {
      requestNewLink,
      loading,
    };
  },
};
</script>
<style scoped>
.links {
  padding-top: 10px;
  text-align: right !important;
}
/* .info {
  padding-bottom: 18px;
} */
</style>
