<template>
  <div class="main-container">
    <Loader v-if="loading" />
    <div class="content">
      <div class="form-container">
        <div class="form">
          <div class="title-container">
            <div class="title">Forgot your password?</div>
            <span v-if="envTag" class="env-tag">{{ envTag }}</span>
          </div>
          <div class="info">
            <p>
              Enter your email address and we will send you an email with a link
              to create a new password
            </p>
          </div>
          <form @submit="forgotPassword">
            <div class="fields">
              <div
                class="field"
                :class="v$.$dirty && v$.email.required.$invalid && 'error'"
              >
                <p class="label">Email address</p>
                <input
                  id="email"
                  v-model="email"
                  name="email"
                  class="input"
                  maxlength="100"
                />
                <p
                  v-if="v$.$dirty && v$.email.required.$invalid"
                  class="validation error-msg"
                >
                  Email address is required
                </p>
                <p
                  v-if="v$.$dirty && v$.email.email.$invalid"
                  class="validation error-msg"
                >
                  Please enter a valid Email address
                </p>
              </div>
              <p class="error-msg invalid" v-if="!!message">{{ message }}</p>
              <div class="action">
                <router-link class="reset" to="/">
                  <button class="secondary">Cancel</button>
                </router-link>
                <button type="submit" :disabled="v$.email.$invalid">
                  Submit
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
      <LoginImage></LoginImage>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import Loader from '../components/Loader.vue'
import LoginImage from '../components/LoginImage.vue'
import { required, email } from '@vuelidate/validators'
import useVuelidate from '@vuelidate/core'
export default {
  setup() {
    return { v$: useVuelidate() }
  },
  data: () => {
    return {
      errors: [],
      email: undefined,
      loading: false,
      message: undefined,
      envTag: window.location.hostname.split('.')[0].split('-')[1]
    }
  },
  validations: {
    email: {
      required,
      email
    }
  },
  components: {
    Loader,
    LoginImage
  },
  methods: {
    forgotPassword: async function (e) {
      e.preventDefault()
      // this.v$.$touch()
      // if (this.v$.$error) return
      if (this.email) {
        this.loading = true
        await fetch(`/api/reset_password/email/${this.email}`)
        this.$router.push({
          name: 'Forgot-Password-Confirmation'
        })
        this.loading = false
      }
    }
  }
}
</script>
<style scoped>
.title {
  margin-bottom: 10px;
}
.info {
  margin-bottom: 38px;
}
.action .secondary {
  margin-right: 16px;
}
</style>
