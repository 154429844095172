<template>
  <div class="main-container">
    <div class="content">
      <div class="form-container">
        <div class="form">
          <p class="title">✅ Success!</p>
          <div class="info">
            <p class="text">
              The new password for your account has been successfully changed. You can now
              continue to use Datamaran by logging in.
            </p>
          </div>
          <div class="action" />
          <div class="links">
            <router-link class="reset" to="/">Log in Datamaran</router-link>
          </div>
        </div>
      </div>
      <LoginImage></LoginImage>
    </div>
  </div>
  <Footer />
</template>

<script>
import Footer from "./Footer.vue";
import LoginImage from "../components/LoginImage.vue";
// @ is an alias to /src
export default {
  components: { Footer, LoginImage },
};
</script>
<style scoped>
.links {
  padding-top: 10px;
  text-align: right !important;
}
.info {
  padding-bottom: 18px;
}
</style>
